import FastClickComponent from "components/libs/fast-click";

import * as _ from "components/helpers/easyJS";
import { log } from "components/helpers/utils";

import popup from "components/helpers/popup";


//import "components/definition/requestAnimationFrame";
//import "components/definition/matchesSelector";

//import scrollEvent from "components/helpers/scrollEvent";
//import scrollTop from "components/helpers/scrollTop";
//import ajax from "components/helpers/ajax";

export default () => {
  FastClickComponent();
  
  /*
new popup('.window', {
    afterOpen: () => {
      log('afterOpen');
    },
    afterClose: () => {
      log('afterClose');
    }
  });
*/
  
  $('.color-list__link').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true
		}
		
	});
	
	$('.js-docItem').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true
		}
		
	});
	
	$('.about-slider').slick({
    dots: false,
    infinite: true,
    speed: 200,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: '<a href="#" class="slick-prev"><svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.9 17.8"><line class="cls-1" x1="50.9" y1="8.9" x2="1.9" y2="8.9"/><polyline class="cls-1" points="10.9 1 1 8.9 10.9 16.8"/></svg></a>',
    nextArrow: '<a href="#" class="slick-next"><svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.9 17.8"><line class="cls-1" x1="1" y1="8.9" x2="50" y2="8.9"/><polyline class="cls-1" points="41 1 50.9 8.9 41 16.8"/></svg></a>'
  });
  
  $('.slider-list').slick({
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow: '<a href="#" class="slick-prev"><svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.9 17.8"><line class="cls-1" x1="50.9" y1="8.9" x2="1.9" y2="8.9"/><polyline class="cls-1" points="10.9 1 1 8.9 10.9 16.8"/></svg></a>',
    nextArrow: '<a href="#" class="slick-next"><svg id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.9 17.8"><line class="cls-1" x1="1" y1="8.9" x2="50" y2="8.9"/><polyline class="cls-1" points="41 1 50.9 8.9 41 16.8"/></svg></a>'
    
  });
};