import "components/definition/matchesSelector";

/*
 *  *******************************************
 *
 *  _.on('click', '.tools', (e) => { log(e.currentTarget); });
 *  _.on('click', document, (e) => { log(e.currentTarget); }, '.section');
 *
 *  _.off('click', '.tools', functionWithName);
 *  _.off('click', document, functionWithName, '.section');
 *  
 *  *******************************************
 *
 *  _.select('.tools');

 *  _.select('.tools', (el) => {
 *    el.classList.add('is-active');
 *  });
 *  
 *  _.select('.tools', () => {}, '.context');
 *
 *  *******************************************
 *
 *  _.selectAll('.tools div')
 *    .forEach((item) => {
 *      log(item);
 *    });
 *
 *  *******************************************
*/

  
export function on(type, selector, fn, context = document){
  
  if(typeof context === 'string')
    context = document.querySelector(context);
  
  context.addEventListener(type, function listener(event) {
    
    let
      element = event.target,
      listeners = []
    ;
    
    if (event.detail && event.detail.selector === selector && event.detail.fn === fn && event.detail.context === context) {
      return context.removeEventListener(type, listener, true);
    }
    
    switch(selector){
      case document:
      
        document.addEventListener(type, fn, false);
      
        setTimeout(() => {
          document.removeEventListener(type, fn, false);
        }, 0);
        
        break;
        
      default:
        
        while (element !== context) {
        
          if (element.matches(selector)) {
            element.addEventListener(type, fn, false);
            listeners.push(element);
          }
          
          element = element.parentNode;
          
        }
        
        setTimeout(() => {
          
          let
            i = listeners.length - 1
          ;
          
          while (i >= 0) {
            listeners[i].removeEventListener(type, fn, false);
            i -= 1;
          }
          
        }, 0);
        
    }
    
  }, true);
  
};

export function off(type, selector, fn, context = document){
  
  let
    event = document.createEvent('CustomEvent')
  ;
  
  event.initCustomEvent(type, false, false, {selector: selector, fn: fn, context: context});
  document.dispatchEvent(event);

};


/* ----------------------------------- */


export function select(selector, fn = undefined, context = document){
  
  if(typeof context === 'string')
    context = document.querySelector(context);
    
  let
    element = context.querySelector(selector)
  ;
  
  if(element && fn !== undefined){
    fn.call(null, element);
  }
  
  return element; 
};

export function selectAll(selector, context = document){
  
  if(typeof context === 'string')
    context = document.querySelector(context);
    
  return Array.from(context.querySelectorAll(selector));
};


/* ----------------------------------- */


/*
export function addClass(selector, fn, context = document){
  return context.querySelector(selector); 
};
*/


