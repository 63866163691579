import * as _ from "components/helpers/easyJS";

class popup {

  constructor(element, params = {}){
  
    this.element = element;
    this.params = params;
    
    this.id = _.select(this.element).getAttribute('href');
    this.contentContainer = _.select(this.id);
    this.parentContentContainer = this.contentContainer.parentNode;
    
    this.template = '<div class="popup__inner"><div class="popup__content"></div></div>';
    
    this.init();
    
  }
  
  init(){
    _.on('click', this.element, (e) => {
      e.preventDefault();
      this.open();
    });
    
    ['.popup', '.popup__close'].forEach((item) => {
      _.on('click', item, (e) => { this.close(); });
    });
    
    _.on('click', this.id, (e) => {
      e.stopPropagation();
    });
    
  }
  
  /*
   *  *******************************************
   *
   *  methods
   *
   *  *******************************************
  */
  
  open(){
  
    this.beforeOpen();
    
    let
      container = this.createElement('popup')
    ;
    
    container.innerHTML = this.template;
    
    _.select('body', (el) => {
      el.insertBefore(container, el.firstChild);

      _.select('.popup__content')
        .appendChild(this.contentContainer);

      container.classList.add('is-open');
    });
    
    this.afterOpen();
    
  }

  close(){
  
    this.beforeClose();
    
    this.parentContentContainer.appendChild(this.contentContainer);
    
    _.select('body', (el) => {
      el.removeChild(
        _.select('.popup')
      );
    });
    
    this.afterClose();
  }
  
  /*
   *  *******************************************
   *
   *  before && after methods
   *
   *  *******************************************
  */
  
  beforeOpen(){
    _.select('body').classList.add('popup-hidden');
    
    if(this.params.beforeOpen !== undefined)
      this.params.beforeOpen();
  }
  
  afterOpen(){
    let
      closeButton = this.createElement('popup__close')
    ;
    
    this.contentContainer
      .appendChild(closeButton);
    
    if(this.params.afterOpen !== undefined)
      this.params.afterOpen();
  }
  
  /* ***************************************** */
  
  beforeClose(){
    let
      closeButton = _.select('.popup__close')
    ;
    
    this.contentContainer
      .removeChild(closeButton);
      
    if(this.params.beforeClose !== undefined)
      this.params.beforeClose();
  }
  
  afterClose(){
    _.select('body').classList.remove('popup-hidden');
    
    if(this.params.afterClose !== undefined)
      this.params.afterClose();
  }
  
  /*
   *  *******************************************
   *
   *  helpers
   *
   *  *******************************************
  */
  
  createElement(className){
    let
      el = document.createElement('div')
    ;
    
    el.classList.add(className);
    
    return el;
  }
  
}

export default popup;
